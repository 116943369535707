.menu-footer:target {
	min-height: 100vh;
}


.menu-footer__list {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
}

.menu-footer__link {
	display: block;
	padding: 0.5em 0;
	text-decoration: none;
	color: inherit;
}