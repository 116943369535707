.mixova__img {
	display: block;
	max-width: 100%;
	margin: 0 auto;
}
	@media (min-width: 646px) {
		.mixova__img {
			float: left;
			margin: 0 0.5em 0 0;
		}
		.mixova__img_two {
			float: right;
			margin: 0;
		}
	}


.mixova__nuanciers {
	list-style: none;
	margin: 1em 0 0;
	padding: 0;
}


@media (min-width: 646px) {
	.mixova__footer {
		clear: both;
		padding-top: 2em;
	}
}