.menu {
    font-family: 'getonelight';
    box-sizing: border-box;
    color: #333;
}


.menu__button {
    position: relative;
    display: block;
    z-index: 30;
    text-decoration: none;
    color: inherit;
    min-height: 55px;
}
	@media (min-width: 48em) {
		.menu__button {
			display: none;
		}
	}


.menu__icon {
	display: block;
	position: relative;
    max-width: 50px;
	top: 14px;
	height: 6px;
	background-color: #333;
	border-radius: 1px;
}
	.menu__icon:before,
	.menu__icon:after {
		content: "";
		position: relative;
		display: block;
		height: 6px;
		background-color: #333;
		border-radius: 1px;
		-webkit-transition: -webkit-transform 0.3s, background-color 0.3s;
	    -moz-transition: -moz-transform 0.3s, background-color 0.3s;
	    -ms-transition: -moz-transform 0.3s, background-color 0.3s;
	    -o-transition: -o-transform 0.3s, background-color 0.3s;
	    transition: transform 0.3s, background-color 0.3s;
	}
	.menu__icon:before {
	    top: -12px;
	}
	.menu__icon:after {
	    top: 6px;
	}

	.menu_open .menu__icon {
		background-color: transparent;
	}
	.menu_open .menu__icon:after {
	    top: -6px;
	    -webkit-transform: rotate(45deg);
	    -moz-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	    -o-transform: rotate(45deg);
	    transform: rotate(45deg);
	}
	.menu_open .menu__icon:before {
	    top: 0;
	    -webkit-transform: rotate(-45deg);
	    -moz-transform: rotate(-45deg);
	    -ms-transform: rotate(-45deg);
	    -o-transform: rotate(-45deg);
	    transform: rotate(-45deg);
	}



.menu__list {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	z-index: 20;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100%;
	list-style: none;
	margin: 0;
	padding: 90px 0 0;
	box-sizing: border-box;
	background-color: #fff;
	background-color: rgba(255, 255, 255, .92);
	color: #000;
	-webkit-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
	-moz-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
	-ms-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
	-o-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
	transition: visibility 0s linear 0.3s, opacity 0.3s linear;
}
	.menu_open .menu__list {
        visibility: visible;
        opacity: 1;
		-webkit-transition-delay: 0s;
		-moz-transition-delay: 0s;
		-ms-transition-delay: 0s;
		-o-transition-delay: 0s;
		transition-delay: 0s;
	}
	@media (min-width: 48em) {
		.menu__list {
			visibility: visible;
			opacity: 1;
			position: relative;
			display: table;
			top: auto;
			left: auto;
			width: 100%;
			min-height: none;
			margin: 0;
			padding: 0;
			background-color: transparent;
			color: #333;
		}
	}


@media (min-width: 48em) {
	.menu__item {
		position: relative;
		display: table-cell;
		white-space: nowrap;
		padding: 0 0.5em;
	}
	.menu__item + .menu__item .menu__link:before {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		content: "I";
		color: #e40133;
	}
	.menu__item:last-child {
		padding-right: 0;
	}
}


.menu__link {
	display: block;
	padding: 0.5em;
	text-align: center;
	text-decoration: none;
	color: inherit;
    line-height: 1.4;
}
	@media (min-width: 48em) {
		.menu__link {
			padding: 0;
			transition: color 0.3s;
		}
		.menu__item:first-child .menu__link {
			overflow: hidden;
			text-indent: 900px;
			width: 26px;
			height: 22px;
			padding-right: 0;
			padding-left: 0;
			background: url(../../images/icons.svg) no-repeat 79.8780487804878% 23%;
		}
		.menu__item:first-child .menu__link_active {
			background-position: 79.8780487804878% 41.25%;
		}
		.menu__link_active {
			color: #fff;
		}
		.menu__link:hover {
			color: #fff;
		}
	}