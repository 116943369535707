.breadcrumb {
	margin-top: 0.5em;
	padding: 0.5em;
	box-sizing: border-box;
	background-color: #e5007e;
}
	@media (min-width: 31em) {
		.breadcrumb {
			padding-right: 1em;
			padding-left: 1em;
		}
	}
	@media (min-width: 662px) {
		.breadcrumb {
			max-width: calc(760px + ((100% - 760px) / 2));
			padding-right: 0;
			padding-left: 0;
		}
	}
	@media (min-width: 99em) {
		.breadcrumb {
			max-width: calc(1015px + ((100% - 1015px) / 2));
		}
	}

.breadcrumb_primaire-impression {
	background-color: #e5007e;
}
.breadcrumb_finitions {
	background-color: #bfcf00;
}
.breadcrumb_facade {
	background-color: #f08700;
}
.breadcrumb_bois-vernis {
	background-color: #918870;
}
.breadcrumb_sols {
	background-color: #81878a;
}
.breadcrumb_metaux {
	background-color: #60ade0;
}
.breadcrumb_peintures-decoratives {
	background-color: #f7e539;
}


.breadcrumb__list {
	list-style: none;
	max-width: 630px;
	margin: 0 auto;
	padding: 0;
}
	@media (min-width: 662px) {
		.breadcrumb__list {
			margin-left: calc((100vw - 630px) / 2);
		}
	}


.breadcrumb__item {
	display: inline-block;
	color: #fff;
}
	.breadcrumb__item:after {
		display: inline-block;
		content: ">";
		color: #333;
	}
	.breadcrumb__item:last-child:after {
		display: none;
	}
	.breadcrumb__item:first-child {
		display: none;
	}
	.breadcrumb__item_current {
		color: #fff;
	}


.breadcrumb__link {
	text-decoration: none;
	color: #333;
	transition: color 0.3s;
}
.breadcrumb__link:hover {
	color: #fff;
}