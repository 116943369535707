.icons {
	list-style: none;
	margin: 0;
	padding: 1em 0;
	min-height: 28px;
}


.icons__item {
	display: inline-block;
	overflow: hidden;
	width: 28px;
	height: 28px;
	text-indent: 100%;
	background-repeat: no-repeat;
	background-image: url(../../images/icons.svg);
}

.icons__item_water {
	background-position: 81.19834710743801% 61.666666666666664%;
}
.icons__item_essence {
	background-position: 0 100%;
}
.icons__item_exterior {
	background-position: 23.140495867768596% 100%;
}
.icons__item_interior {
	background-position: 46.28099173553719% 100%;
}
.icons__item_aplus {
	background-image: url(../../images/Aplus.jpg);
	background-size: 100%;
}
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		.icons__item_aplus {
			background-image: url(../../images/Aplusx2.jpg);
		}
	}
.icons__item_nf {
	background-image: url(../../images/NF-environnement.png);
	background-size: 100%;
}
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		.icons__item_nf {
			background-image: url(../../images/NF-environnementx2.png);
		}
	}