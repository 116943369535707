.header {
	background: #f7a600 url(../../images/header.svg) no-repeat center center;
	background-size: cover;
	border-bottom: 1px solid #fff;
}


.header__container {
	max-width: 425px;
	min-height: 150px;
	box-sizing: border-box;
	margin: 0 auto;
	padding-top: 0.5em;
}
	@media (min-width: 48em) {
		.header__container {
			position: relative;
			width: auto;
			max-width: 1160px;
			height: 175px;
			padding: 0.5em;
		}
	}


.header__logo {
	position: relative;
	float: left;
	width: 22%;
	height: 116px;
	margin: 0 0 0 6%;
}
	@media (min-width: 48em) {
		.header__logo {
			position: absolute;
			float: none;
			width: 85px;
			height: auto;
			top: 1em;
			left: 1em;
			margin: 0;
		}
	}
	@media (min-width: 60em) {
		.header__logo {
			width: 10%;
			top: auto;
			bottom: 1em;
		}
	}

.header__logo-container {
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
}
	@media (min-width: 48em) {
		.header__logo-container {
			position: static;
			width: auto;
			left: auto;
			bottom: auto;
		}
	}


.header__menu {
    width: 60px;
	margin: 0 auto;
    color: #fff;
    font-size: 1.2em;
}
	@media (min-width: 48em) {
		.header__menu {
			float: none;
			position: absolute;
			width: 100%;
			bottom: 1.5em;
			left: 0;
			margin: 0;
			padding: 0 1em;
			box-sizing: border-box;
    		font-size: 2.2vw;
		}
	}
	@media (min-width: 60em) {
		.header__menu {
			width: auto;
			left: auto;
			right: 16px;
			right: 1rem;
			padding: 0;
			font-size: 1.9vw;
		}
	}
	@media (min-width: 1176px) {
		.header__menu {
			font-size: 1.3em;
		}
	}

.header__search {
	float: right;
	margin: 2em 1% 0 1%;
	width: 64%;
}
	@media (min-width: 48em) {
		.header__search {
			position: absolute;
			top: 1em;
			right: 1em;
			width: 270px;
			margin: 0;
			padding: 0;
			white-space: nowrap;
		}
	}
	@media (min-width: 1176px) {
		.header__search {
			right: 0;
		}
	}