.products__filters {
    padding-top: 0.5em;
    font-size: 0.9em;
}


.products__list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
    @media (min-width: 35.625em) {
        .products__list {
            max-width: 760px;
            margin: 0 auto;
        }
    }
    @media (min-width: 47.5em) {
        .products__list {
            background: #fff;
            background: rgba(255, 255, 255, 0.9);
        }
    }
    @media (min-width: 99em) {
        .products__list {
            max-width: 1015px;
        }
    }


.products__item {
    width: 100%;
    box-sizing: border-box;
    margin-top: 1em;
    padding: 1em 0;
}
    @media (min-width: 23.75em) {
        .products__item {
            float: left;
            width: 50%;
            margin-top: 2em;
        }
        .products__item:nth-child(2n+1) {
            clear: both;
        }
    }
    @media (min-width: 35.625em) {
        .products__item {
            width: 33.3333333333334%;
        }
        .products__item:nth-child(2n+1) {
            clear: none;
        }
        .products__item:nth-child(3n+1) {
            clear: both;
        }
    }
    @media (min-width: 99em) {
        .products__item {
            width: 25%;
        }
        .products__item:nth-child(3n+1) {
            clear: none;
        }
        .products__item:nth-child(4n+1) {
            clear: both;
        }
    }



.products__title {
    margin: 0;
    padding: 0 8px;
    padding: 0 0.5rem;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 1.4em;
}
    @media (min-width: 23.75em) {
        .products__title {
            line-height: 1.23em;
            min-height: 3.69em;
        }
    }
    @media (min-width: 47.5em) {
        .products__title {
            max-width: 190px;
            margin: 0 auto;
        }
    }


.products__description {
    margin: 0.5em;
    text-align: center;
}
    @media (min-width: 23.75em) {
        .products__description {
            line-height: 1.23em;
            min-height: 3.69em;
        }
    }
    @media (min-width: 47.5em) {
        .products__description {
            max-width: 190px;
            margin: 0.5em auto;
        }
    }


.products__link {
    text-decoration: none;
    color: inherit;
}
.products__link_primaire-impression {
    color: #af1380;
}
.products__link_finitions {
    color: #7db828;
}
.products__link_facade {
    color: #ed7203;
}
.products__link_bois-vernis {
    color: #897e65;
}
.products__link_sols {
    color: #70777a;
}
.products__link_metaux {
    color: #3c9ad5;
}
.products__link_peintures-decoratives {
    color: #ddd70e;
}


.products__container {
    padding: 2em 0;
    background: url(../../images/background.svg) #fff no-repeat 0 100%;
    background-size: cover;
}
    @media (min-width: 47.5em) {
        .products__container {
            max-width: 190px;
            margin: 0 auto;
        }
    }


.products__img-container {
    position: relative;
    width: 120px;
    height: 170px;
    margin: 0 auto;
}
.products__img {
    display: block;
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}


.products__icons {
    padding: 0 0.5em;
}


.products__see {
    display: block;
    text-decoration: none;
    color: #390823;
    background-color: #fff;
    padding: 0.5em;
    text-align: center;
    text-transform: lowercase;
    font-family: 'getonelight';
    transition: color 0.3s;
}
.products__see:hover {
    color: #fff;
}
.products__see_primaire-impression {
    background-color: #e5007e;
}
.products__see_finitions {
    background-color: #7db828;
}
.products__see_facade {
    background-color: #ed7203;
}
.products__see_bois-vernis {
    background-color: #897e65;
}
.products__see_sols {
    background-color: #70777a;
}
.products__see_metaux {
    background-color: #3c9ad5;
}
.products__see_peintures-decoratives {
    background-color: #ddd70e;
}