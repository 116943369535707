@media (min-width: 48em) {
	.catalog {
		background-repeat: no-repeat;
		background-size: 100% auto;
		min-height: 44em;
	}
	.catalog_primaire-impression {
		background-image: url(../../images/catalog/sous-couches-1200.jpg);
	}
	.catalog_finitions {
		background-image: url(../../images/catalog/finitions-1200.jpg);
	}
	.catalog_facade {
		background-image: url(../../images/catalog/facades-1200.jpg);
	}
	.catalog_sols {
		background-image: url(../../images/catalog/sols-1200.jpg);
	}
	.catalog_bois-vernis {
		background-image: url(../../images/catalog/bois-vernis-1200.jpg);
	}
	.catalog_metaux {
		background-image: url(../../images/catalog/metaux-1200.jpg);
	}
	.catalog_peintures-decoratives {
		background-image: url(../../images/catalog/peintures-decoratives-1200.jpg);
	}
}
@media (min-width: 1200px) {
	.catalog_primaire-impression {
		background-image: url(../../images/catalog/sous-couches-2500.jpg);
	}
	.catalog_finitions {
		background-image: url(../../images/catalog/finitions-2500.jpg);
	}
	.catalog_facade {
		background-image: url(../../images/catalog/facades-2500.jpg);
	}
	.catalog_sols {
		background-image: url(../../images/catalog/sols-2500.jpg);
	}
	.catalog_bois-vernis {
		background-image: url(../../images/catalog/bois-vernis-2500.jpg);
	}
	.catalog_metaux {
		background-image: url(../../images/catalog/metaux-2500.jpg);
	}
	.catalog_peintures-decoratives {
		background-image: url(../../images/catalog/peintures-decoratives-2500.jpg);
	}
}