@font-face {
    font-family: 'getonelight';
    src: url('../../fonts/robert_corseanschi_-_getone-lig-webfont.eot');
    src: url('../../fonts/robert_corseanschi_-_getone-lig-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/robert_corseanschi_-_getone-lig-webfont.woff2') format('woff2'),
         url('../../fonts/robert_corseanschi_-_getone-lig-webfont.woff') format('woff'),
         url('../../fonts/robert_corseanschi_-_getone-lig-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
	position: relative;
    height: 100%;
	min-height: 100%;
}
body {
	margin: 0;
	padding: 0;
	height: 100%;
	font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    font-size: 100%;
    color: #767e85;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 300;
}
a {
    color: #f18800;
}
a img {
    border: none;
}