.footer {
	padding-top: 2em;
}
.footer_pinned {
	margin-top: -12%;
	padding-top: 8%;
}


.footer__menu {
	margin-bottom: 2em;
}
	@media (min-width: 60em) {
		.footer__menu {
			display: none;
		}
	}
.footer__menu_hidden {
	display: none;
}


.footer__legal {
	padding: 0.5em 0;
}