.activites {
	list-style: none;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}


.activites__link {
	display: block;
	padding: 0.35em;
	text-decoration: none;
	color: #fff;
	background-repeat: no-repeat;
	background-image: url(../../images/activites/sprite.svg);
	background-size: 100% auto;
}
	@media (min-width: 26em) {
		.activites__link {
			background-size: auto 700%;
		}
	}

.activites__link_primaire-impression {
	background-position: 0 100%;
}
.activites__link_finitions {
	background-position: 0 50.211778290993074%;
}
.activites__link_facade {
	background-position: 0 16.772533179457586%;
}
.activites__link_bois-vernis {
	background-position: 0 33.429861111111116%;
}
.activites__link_sols {
	background-position: 0 66.91722543352603%;
}
.activites__link_metaux {
	background-position: 0 0;
}
.activites__link_peintures-decoratives {
	background-position: 0 83.48719723183392%;
}