.form {
	margin: 0;
	padding: 0;
}

.form_search {
	overflow: hidden;
}


.form__fieldset {
	border: none;
	margin: 0;
	padding: 0;
}


.form__legend {
	margin: 0;
	padding: 0 0 1em;
	text-transform: uppercase;
}


.form__label {
	display: block;
	margin-bottom: 0.25em;
}
.form__input + .form__label {
	margin-top: 0.5em;
}
.form__label_required:after {
	display: inline-block;
	content: "*";
	margin-left: 0.25em;
}


.form__input {
	display: block;
	max-width: 280px;
	width: 100%;
	box-sizing: border-box;
	margin: 0;
	padding: 0.25em;
	border: 1px solid #ccc;
	font-size: inherit;
	color: inherit;
	font-family: inherit;
	-webkit-appearance: none;
	border-radius: 0;
}
.form__input_textarea {
	max-width: 500px;
	min-height: 12em;
}

.form__input_inline {
	display: inline-block;
	width: auto;
	line-height: 18px;
	max-width: 220px;
}
.form__input_search {
	float: left;
	width: 80%;
}
.form__input_error {
	outline: 1px solid red;
}


.form__button {
	margin: 1em 0 0;
	padding: 0.5em;
	font-size: inherit;
	background: #333;
	color: #fff;
	font-family: inherit;
	border: none;
	cursor: pointer;
}

.form__button_inline {
	display: inline-block;
	height: 28px;
	line-height: 18px;
	margin: 0;
	padding: 0.25em;
}
.form__button_search {
	overflow: hidden;
	text-indent: 100px;
	background: url(../../images/icons.svg) 100% 0 no-repeat;
	margin-left: 1%;
	width: 19%;
	float: left;
	box-sizing: border-box;
}


.form__messages {
	padding-top: 1em;
	padding-bottom: 1em;
	margin-bottom: 1em;
	border: 1px solid transparent;
}
.form__messages_error {
	color: #a94442;
	background-color: #f2dede;
	border-color: #ebccd1;
}
.form__messages_success {
	color: #3c763d;
	background-color: #dff0d8;
	border-color: #d6e9c6;
}


.form__hidden {
	display: none;
}