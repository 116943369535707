.properties {
	line-height: 1.3em;
}

.properties__label {
	float: left;
	clear: both;
	padding-right: 0.25em;
	font-weight: bold;
}

.properties__value {
	margin: 0;
}