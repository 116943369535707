@media (min-width: 26em) {
	.page_mixova {
		padding-right: 5%;
		padding-left: 5%;
		background: url(../../images/fond-mixova.svg) no-repeat;
		background-size: 100% auto;
	}
}
@media (min-width: 955px) {
	.page_mixova {
		background-position: left 0 bottom 520px;
	}
}
@media (min-width: 26em) {
	.page_peintures-innova {
		padding-right: 5%;
		padding-left: 5%;
		padding-bottom: 50%;
		background: url(../../images/usine-innova.jpg) no-repeat center bottom;
		background-size: cover;
	}
}


.page__container {
	max-width: 780px;
	margin: 0 auto;
	padding: 1em 0.5em;
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.67);
}
	@media (min-width: 26em) {
		.page__container {
			border: 1px solid #E9E8E8;
			border-top: none;
		}
	}
	@media (min-width: 31em) {
		.page__container {
			padding: 2em 1em;
		}
	}
	@media (min-width: 48em) {
		.page__container {
			padding-top: 3em;
			padding-bottom: 3em;
		}
	}
	@media (min-width: 1032px) {
		.page__container {
			padding: 4em 0;
		}
	}
.page_peintures-innova .page__container {
	border: none;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.48);
}


.page__title {
	max-width: 600px;
	margin: 0 auto;
    color: #464d54;
    font-size: 1.6em;
    text-transform: uppercase;
}
.page__title_alt {
	color: #2274b7;
}
.page_peintures-innova .page__title {
	color: #fff;
}


.page__content {
	max-width: 600px;
	margin: 0 auto;
	color: #464d54;
	line-height: 1.3;
}
@media (min-width: 48em) {
	.page__content {
		text-align: justify;
	}
}
.page_peintures-innova .page__content {
	color: #fff;
}


.page__content h2 {
	font-size: 1.4em;
}