.attachs {
	display: table;
}


.attachs__item {
	display: table-row;
}


.attachs__link {
	display: table-cell;
	padding: 8px 16px;
	padding: 0.5rem 1rem;
	background: #e5007e;
	text-decoration: none;
	color: #390823;
	font-family: 'getonelight';
	font-size: 1.125em;
	transition: color 0.3s;
}
	.attachs__item + .attachs__item .attachs__link {
		border-top: 0.5em solid #fff;
	}

.attachs__link_primaire-impression {
	background-color: #e5007e;
}
.attachs__link_finitions {
	background-color: #bfcf00;
}
.attachs__link_facade {
	background-color: #f08700;
}
.attachs__link_bois-vernis {
	background-color: #918870;
}
.attachs__link_sols {
	background-color: #81878a;
}
.attachs__link_metaux {
	background-color: #60ade0;
}
.attachs__link_peintures-decoratives {
	background-color: #f7e539;
}

.attachs__link:hover {
	color: #fff;
}