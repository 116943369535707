.feature__title {
	margin: 0 0 8px;
	margin: 0 0 0.5rem;
	font-size: 1.25em;
	text-transform: uppercase;
}


.feature__content {
	font-size: 1.125em;
}
.feature__content ul {
	margin: 0;
}