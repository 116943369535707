.menu-bottom {
	max-width: 1200px;
	margin: 0 auto;
}

.menu-bottom__list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: table;
	width: 100%;
}

.menu-bottom__item {
	display: table-cell;
	padding: 0 0.5em;
	text-align: center;
}
.menu-bottom__item + .menu-bottom__item {
	text-align: left;
	border-left: 1px solid blue;
}

.menu-bottom__link {
	text-decoration: none;
	color: #0060b0;
}