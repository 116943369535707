.legend {
	max-width: 780px;
	margin: 0 auto;
	padding: 1em 0.5em 2em;
}


.legend__list {
	list-style: none;
	margin: 0;
	padding: 0;
}


.legend__item {
	position: relative;
	padding-left: 20px;
}
.legend__item:before {
	display: block;
	content: "";
	position: absolute;
	width: 10px;
	height: 10px;
	left: 0;
	top: 50%;
	margin-top: -5px;
	vertical-align: middle;
	border-radius: 50%;
}
.legend__item_innova:before {
	background-color: #e50040;
}
.legend__item_instinct:before {
	background-color: #babb53;
}