.slide {
	position: relative;
	max-width: 1160px;
	margin: 0 auto;
	overflow: hidden;
}


@media (min-width: 48em) {
	.slide__slides {
		position: relative;
		/* avoid round error with a even value */
		width: 400%;
		transition: transform 0.3s;
		overflow: hidden;
	}
	#slide__play:checked ~ .slide__slides {
	    animation: cycle 15s linear infinite;
	}

	@keyframes cycle {
	    0%, 30%, 100%  { left: 0; }
	    33%, 63% { left: -100%; }
	    66%, 97% { left: -200%; }
	}

	#slide__1:checked ~ .slide__slides {
		transform: translateX(0);
	}
	#slide__2:checked ~ .slide__slides {
		transform: translateX(-25%);
	}
	#slide__3:checked ~ .slide__slides {
		transform: translateX(-50%);
	}
}


.slide__item + .slide__item {
	padding: 1em 0;
}
	@media (min-width: 34em) {
		.slide__item + .slide__item {
			padding: 1em;
		}
	}
	@media (min-width: 48em) {
		.slide__item {
			float: left;
			width: 25%;
		}
		.slide__item + .slide__item {
			padding: 0;
		}
	}


.slide__input {
	display: none;
}


.slide__control {
	display: none;
}
	@media (min-width: 48em) {
		.slide__control {
			display: block;
			position: absolute;
			top: 50%;
			transform: translate(-50%);
			left: 0.5em;
			z-index: 5;
		}
		.slide__control_next {
			left: auto;
			right: 66px;
			right: calc(58px + 0.5em);
		}
	}


@media (min-width: 48em) {
	.slide__label {
		display: block;
		position: absolute;
		width: 58px;
		height: 58px;
		cursor: pointer;
		user-select: none;
		background: url(../../images/icons.svg) no-repeat;
	}
	.slide__label_prev {
		background-position: 44.07713498622589% 0;
	}
	.slide__label_next {
		background-position: 44.07713498622589% 65.36312849162012%;
	}

	#slide__play:checked ~ .slide__control_next .slide__label_one,
	#slide__play:checked ~ .slide__control_prev .slide__label_two {
		animation: arrow_one 15s linear infinite;
	}
	#slide__play:checked ~ .slide__control_next .slide__label_two,
	#slide__play:checked ~ .slide__control_prev .slide__label_three {
		animation: arrow_two 15s linear infinite;
	}
	#slide__play:checked ~ .slide__control_next .slide__label_three,
	#slide__play:checked ~ .slide__control_prev .slide__label_one {
		animation: arrow_three 15s linear infinite;
	}
	@keyframes arrow_one {
	    0%, 30%  { z-index: -1; }
	    33%, 63% { z-index: -1; }
	    66%, 97% { z-index: 5; }
	    100%  { z-index: -1; }
	}
	@keyframes arrow_three {
	    0%, 30%  { z-index: -1; }
	    33%, 63% { z-index: 5; }
	    66%, 97% { z-index: -1; }
	    100%  { z-index: -1; }
	}
	@keyframes arrow_two {
	    0%, 30%  { z-index: 5; }
	    33%, 63% { z-index: -1; }
	    66%, 97% { z-index: -1; }
	    100%  { z-index: -1; }
	}

	#slide__1:checked ~ .slide__control_next .slide__label_one,
	#slide__1:checked ~ .slide__control_next .slide__label_three {
		z-index: -1;
	}
	#slide__1:checked ~ .slide__control_next .slide__label_two {
		z-index: 5;
	}
	#slide__1:checked ~ .slide__control_prev .slide__label_one,
	#slide__1:checked ~ .slide__control_prev .slide__label_two {
		z-index: -1;
	}
	#slide__1:checked ~ .slide__control_prev .slide__label_three {
		z-index: 5;
	}

	#slide__2:checked ~ .slide__control_next .slide__label_one,
	#slide__2:checked ~ .slide__control_next .slide__label_two {
		z-index: -1;
	}
	#slide__2:checked ~ .slide__control_next .slide__label_three {
		z-index: 5;
	}
	#slide__2:checked ~ .slide__control_prev .slide__label_two,
	#slide__2:checked ~ .slide__control_prev .slide__label_three {
		z-index: -1;
	}
	#slide__2:checked ~ .slide__control_prev .slide__label_one {
		z-index: 5;
	}

	#slide__3:checked ~ .slide__control_next .slide__label_two,
	#slide__3:checked ~ .slide__control_next .slide__label_three {
		z-index: -1;
	}
	#slide__3:checked ~ .slide__control_next .slide__label_one {
		z-index: 5;
	}
	#slide__3:checked ~ .slide__control_prev .slide__label_one,
	#slide__3:checked ~ .slide__control_prev .slide__label_three {
		z-index: -1;
	}
	#slide__3:checked ~ .slide__control_prev .slide__label_two {
		z-index: 5;
	}
}


.slide__bullets {
	display: none;
}
	@media (min-width: 48em) {
		.slide__bullets {
			display: block;
			position: absolute;
			bottom: 1em;
			right: 1em;
		}
		.slide__bullet {
			display: inline-block;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background: #000;
			cursor: pointer;
			user-select: none;
			transition: background-color 0.3s;
		}
		#slide__play:checked ~ .slide__bullets .slide__bullet_one {
			animation: bullet_one 15s linear infinite;
		}
		#slide__play:checked ~ .slide__bullets .slide__bullet_two {
			animation: bullet_two 15s linear infinite;
		}
		#slide__play:checked ~ .slide__bullets .slide__bullet_three {
			animation: bullet_three 15s linear infinite;
		}
		@keyframes bullet_one {
		    0%, 30%  { background-color: #eee; }
		    33%, 63% { background-color: #000; }
		    66%, 97% { background-color: #000; }
		    100%  { background-color: #000; }
		}
		@keyframes bullet_two {
		    0%, 30%  { background-color: #000; }
		    33%, 63% { background-color: #eee; }
		    66%, 97% { background-color: #000; }
		    100%  { background-color: #000; }
		}
		@keyframes bullet_three {
		    0%, 30%  { background-color: #000; }
		    33%, 63% { background-color: #000; }
		    66%, 97% { background-color: #eee; }
		    100%  { background-color: #000; }
		}
		#slide__1:checked ~ .slide__bullets .slide__bullet_one,
		#slide__2:checked ~ .slide__bullets .slide__bullet_two,
		#slide__3:checked ~ .slide__bullets .slide__bullet_three {
			background-color: #eee;
		}
	}


@media (min-width: 48em) {
	.slide__container {
		position: relative;
		padding-bottom: 50%;
		background-position: 0 0, right 0, right 100%;
		background-repeat: no-repeat;
		background-size: 66.666666666667%, 33.333333333334%, 33.333333333334%;
	}
	.slide__container_one {
		background-image: url(../../images/slide/1-left-800.jpg),
					url(../../images/slide/1-right-400.jpg),
					url(../../images/slide/1-bottom-400.jpg);
	}
	.slide__container_two {
		background-image: url(../../images/slide/2-left-800.jpg),
					url(../../images/slide/2-right-400.jpg);
		background-position: 0 0, right 100%;
	}
	.slide__container_three {
		background-image: url(../../images/slide/3-left-800.jpg),
					url(../../images/slide/3-right-400.jpg),
					url(../../images/slide/3-bottom-400.jpg);
	}
}
@media (min-width: 1201px) {
	.slide__container_one {
		background-image: url(../../images/slide/1-left-1600.jpg),
					url(../../images/slide/1-right-800.jpg),
					url(../../images/slide/1-bottom-800.jpg);
	}
	.slide__container_two {
		background-image: url(../../images/slide/2-left-1600.jpg),
					url(../../images/slide/2-right-800.jpg);
	}
	.slide__container_three {
		background-image: url(../../images/slide/3-left-1600.jpg),
					url(../../images/slide/3-right-800.jpg),
					url(../../images/slide/3-bottom-800.jpg);
	}
}


.slide__title {
	max-width: 1160px;
	margin: 0 auto;
	padding: 0.5em;
	color: #2274b7;
	font-size: 8vw;
}
	@media (min-width: 26em) {
		.slide__title {
			font-size: 2em;
		}
	}
	@media (min-width: 48em) {
		.slide__title {
			padding: 0.5em 1em;
			text-align: right;
			background-color: #fff;
		}
	}

.slide__title_highlight {
	color: #e40133;
}


.slide__content {
	padding: 0 8px;
	padding: 0 0.5rem;
	font-size: 6vw;
}
	@media (min-width: 26em) {
		.slide__content {
			font-size: 2em;
		}
	}
	@media (min-width: 48em) {
		.slide__content {
			position: absolute;
			top: 50%;
			left: 50%;
			padding: 0;
			transform: translateX(-50%) translateY(-50%);
			z-index: 5;
			font-size: 1.3em;
		}
	}
	@media (min-width: 64em) {
		.slide__content {
			font-size: 1.5em;
		}
	}

.slide__content_activites {
	padding: 0;
}
	@media (min-width: 48em) {
		.slide__content_activites {
			top: 14%;
			left: 33.33%;
			transform: translateX(-50%) translateY(0);
		}
	}
	@media (min-width: 48em) {
		.slide__content_activites:before {
			display: block;
			position: absolute;
			z-index: -1;
			width: 100%;
			height: 100%;
			content: "";
			background: #fff;
			top: -1em;
			left: -1em;
		}
	}
	@media (min-width: 80em) {
		.slide__content_activites:before {
			top: -1.5em;
			left: -1.5em;
		}
	}

.slide__content_mixova {
	padding-top: 75%;
	background-image: url(../../images/slide/2-left-800.jpg);
	background-size: cover;
}
	@media (min-width: 48em) {
		.slide__content_mixova {
			top: 15%;
			left: 40%;
			padding-top: 0;
			transform: translateX(-50%) translateY(0);
			background-image: none;
		}
	}
	@media (min-width: 80em) {
		.slide__content_mixova {
			left: 47.5%;
		}
	}

.slide__content_points {
	padding-top: 75%;
	background-image: url(../../images/slide/3-left-800.jpg);
	background-size: cover;
}
@media (min-width: 48em) {
	.slide__content_points {
		padding-top: 0;
		background-image: none;
	}
}


.slide__subtitle {
	display: none;
}
	@media (min-width: 48em) {
		.slide__subtitle {
			display: block;
			margin: 0;
			font-size: 2em;
			font-weight: 300;
			text-transform: uppercase;
			color: #2b353e;
		}
	}


@media (min-width: 48em) {
	.slide__indent {
		display: block;
		margin-left: 4em;
	}
}


.slide__button {
	font-size: 3.5vw;
	margin: 0 0.5em;
}
	@media (min-width: 33em) {
		.slide__button {
			font-size: 1em;
		}
	}
	@media (min-width: 48em) {
		.slide__button {
			position: absolute;
			bottom: 12.5%;
			right: 1em;
			width: 18em;
			margin: 0;
		}
	}
	@media (min-width: 56em) {
		.slide__button {
			right: auto;
			left: 33.33333334%;
			width: auto;
			bottom: 15%;
			white-space: nowrap;
		}
	}
	@media (min-width: 64em) {
		.slide__button {
			font-size: 1.2em;
		}
	}

@media (min-width: 48em) {
	.slide__button_top {
		bottom: auto;
		top: 3em;
		left: 0;
	}
}
@media (min-width: 48em) {
	.slide__button_mixova {
		left: auto;
		right: 33.33333334%;
		bottom: 30%;
	}
}