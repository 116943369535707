.results {
	list-style: none;
	margin: 0;
	padding: 0;
}


.results__item {
	padding: 1em 0;
}


.results__link {
	display: block;
	text-decoration: none;
}
.results__link:before {
	display: inline-block;
	content: "";
	width: 40px;
	height: 40px;
	vertical-align: middle;
	background: url(../../images/icons.svg) 0 0 no-repeat;
}