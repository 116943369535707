.addresses {
	padding-top: 2em;
}


.addresses__list {
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
	text-align: left;
}


.addresses__title {
	margin: 0 0 16px;
	margin: 0 0 1rem;
}
.addresses__list + .addresses__title {
	margin-top: 48px;
	margin-top: 3rem;
}


.addresses__item {
	cursor: pointer;
}

.addresses__item + .addresses__item {
	padding-top: 1em;
}
	@media (min-width: 48em) {
		.addresses__item {
			float: left;
			width: 50%;
			padding: 1em;
			box-sizing: border-box;
		}
		.addresses__item:nth-child(2n+1) {
			clear: both;
		}
	}


.addresses__pos {
	font-weight: bold;
}