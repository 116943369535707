.button {
	display: block;
	position: relative;
	margin: 0.5em 0;
	padding: 1.5em 0.5em 1.5em 80px;
	text-decoration: none;
	color: #fff;
	text-transform: uppercase;
	font-family: 'getonelight';
	background-color: #2b353e;
}
@media (min-width: 48em) {
	.button {
		margin: 0;
		padding-top: 1em;
		padding-bottom: 1em;
	}
}
@media (min-width: 48em) {
	.button_light {
		background-color: #fff;
		color: #000;
	}
}

.button:before {
	display: block;
	position: absolute;
	top: 50%;
	left: 20px;
	margin-top: -20px;
	content: "";
	width: 40px;
	height: 40px;
	vertical-align: middle;
	background: url(../../images/icons.svg) 0 37% no-repeat;
}
@media (min-width: 48em) {
	.button_light:before {
		background-position: 0 0;
	}
}
