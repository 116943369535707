.categories {
	max-width: 1160px;
	margin: 0 auto;
}


.categories__list {
	list-style: none;
    margin: 0;
	padding: 0;
	overflow: hidden;
}



.categories__item {
	position: relative;
	padding-bottom: 96.5625%;
	background: #333;
	border-top: 1px solid #fff;
}
	@media (min-width: 400px) {
		.categories__item {
			float: left;
			width: 50%;
			padding-bottom: 48.28125%;
			border-left: 1px solid #fff;
			box-sizing: border-box;
		}
	}
	@media (min-width: 800px) {
		.categories__item {
			width: 33.33333333333333%;
			padding-bottom: 32.1875%;
		}
	}


.categories__link {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-decoration: none;
}


.categories__title {
	position: absolute;
	bottom: 7.5%;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	font-weight: 300;
	font-size: 1.5em;
	margin: 0;
	padding: 8px;
	padding: 0.5rem;
	text-decoration: none;
	text-transform: uppercase;
	color: #fff;
	z-index: 3;
	background-image: url(../../images/activites/sprite.svg);
	background-size: auto 700%;
}
	@media (min-width: 64em) {
		.categories__title {
			padding-left: 1em;
		}
	}
	@media (min-width: 800px) {
		.categories__title {
			font-size: 1em;
		}
	}
	@media (min-width: 80em) {
		.categories__title {
			font-size: 1.2em;
		}
	}

.categories__title_primaire-impression {
	background-position: 0 100%;
}
.categories__title_finitions {
	background-position: 0 50.211778290993074%;
}
.categories__title_facade {
	background-position: 0 16.772533179457586%;
}
.categories__title_bois-vernis {
	background-position: 0 33.429861111111116%;
}
.categories__title_sols {
	background-position: 0 66.91722543352603%;
}
.categories__title_metaux {
	background-position: 0 0;
}
.categories__title_peintures-decoratives {
	background-position: 0 83.48719723183392%;
}

.categories__title:before {
	display: inline-block;
	content: "";
	width: 40px;
	height: 40px;
	vertical-align: middle;
	background: url(../../images/icons.svg) 0 74% no-repeat;
}


.categories__img {
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2;
}