.product {
	max-width: 760px;
	margin: 0 auto;
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.9);
	color: #4d4e4f;
}
	@media (min-width: 60em) {
		.product {
			border: 1px solid #E9E8E8;
			border-top: none;
			box-sizing: border-box;
			padding: 0;
		}
	}
	@media (min-width: 1135px) {
		.product {
			background-image: url(../../images/background-1.svg);
			background-repeat: no-repeat;
			background-size: cover;
		}
	}


.product__content {
	position: relative;
	z-index: 5;
	max-width: 630px;
	margin: 0 auto;
	padding: 0 0.5em 2em;
}
	@media (min-width: 31em) {
		.product__content {
			padding: 0 1em 2em;
		}
	}
	@media (min-width: 662px) {
		.product__content {
			padding-right: 0;
			padding-left: 0;
		}
	}
.product__content:after {
	display: block;
	content: "";
	position: absolute;
	width: 100%;
	max-width: 758px;
	height: 100%;
	top: 0;
	left: 0;
	z-index: -1;
	background-color: #fff;
}
	@media (min-width: 630px) {
		.product__content:after {
			width: 100vw;
			left: calc((630px - 100vw) / 2);
		}
	}
	@media (min-width: 760px) {
		.product__content:after {
			left: -75px;
			left: calc((630px - 758px) / 2);
		}
	}


.product__title {
	max-width: 630px;
	margin: 0 auto;
	padding: 16px 8px 0;
	padding: 1rem 0.5rem 0;
	text-transform: uppercase;
	color: #702382;
	font-size: 1.5em;
}
	@media (min-width: 31em) {
		.product__title {
			padding: 32px 16px 0;
			padding: 2rem 1rem 0;
		}
	}
	@media (min-width: 662px) {
		.product__title {
			padding-right: 0;
			padding-left: 0;
		}
	}
	@media (min-width: 48em) {
		.product__title {
			padding-top: 48px;
			padding-top: 3rem;
		}
	}

.product__title_primaire-impression {
	color: #af1380;
}
.product__title_finitions {
	color: #7db828;
}
.product__title_facade {
	color: #ed7203;
}
.product__title_bois-vernis {
	color: #897e65;
}
.product__title_sols {
	color: #70777a;
}
.product__title_metaux {
	color: #3c9ad5;
}
.product__title_peintures-decoratives {
	color: #ddd70e;
}


.product__description {
	max-width: 630px;
	margin: 0 auto;
	padding: 0 8px;
	padding: 0 0.5rem;
	font-size: 1.5em;
	color: #525151;
}
	@media (min-width: 31em) {
		.product__description {
			padding: 0 16px;
			padding: 0 1rem;
		}
	}
	@media (min-width: 662px) {
		.product__description {
			padding-right: 0;
			padding-left: 0;
		}
	}


.product__img {
	display: block;
	margin: 1em auto;
	max-width: 255px;
	width: 50%;
	height: auto;
}

	@media (min-width: 31em) {
		.product__img {
			margin-top: 2em;
			margin-bottom: 2em;
		}
	}
	@media (min-width: 48em) {
		.product__img {
			max-width: none;
			width: 255px;
			margin-top: 3em;
			margin-bottom: 3em;
		}
	}


.product__feature + .product__feature {
	margin-top: 2em;
}


.product__properties {
	padding: 1em 0;
}