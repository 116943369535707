.filters {
	display: none;
	margin: 0;
}
	@media (min-width: 48em) {
		.filters {
			display: block;
			max-width: calc(760px + ((100% - 760px) / 2));
			padding: 0.5em 0;
		}
	}

.filters_primaire-impression {
	background-color: #e5007e;
}
.filters_finitions {
	background-color: #bfcf00;
}
.filters_facade {
	background-color: #f08700;
}
.filters_bois-vernis {
	background-color: #918870;
}
.filters_sols {
	background-color: #81878a;
}
.filters_metaux {
	background-color: #60ade0;
}
.filters_peintures-decoratives {
	background-color: #f7e539;
}


.filters__container {
	max-width: 630px;
	margin-left: calc((100vw - 630px) / 2);
}


.filters__list {
	list-style: none;
	margin: 0;
	padding: 0;
}
	@media (min-width: 48em) {
		.filters__list {
			display: flex;
			flex-wrap: wrap;
			overflow: hidden;
		}
	}



@media (min-width: 48em) {
	.filters__item {
		display: flex;
		justify-content: center;
		align-items: center;
		float: left;
		width: 24%;
		box-sizing: border-box;
		margin: 0.5%;
		padding: 0.5em;
		cursor: pointer;
    	font-family: 'getonelight';
    	background: #333;
    	color: #fff;
    	user-select: none;
    	text-align: center;
    	transition: background-color 0.5s;
	}
	.filters__item:nth-child(4n+1) {
		clear: both;
	}
	.filters__item_primaire-impression {
		background-color: #af1380;
	}
	.filters__item_primaire-impression.filters__item_selected,
	.filters__item_primaire-impression:hover {
		background-color: #960067;
	}
	.filters__item_finitions {
		background-color: #7db828;
	}
	.filters__item_finitions.filters__item_selected,
	.filters__item_finitions:hover {
		background-color: #649F0F;
	}
	.filters__item_facade {
		background-color: #ed7203;
	}
	.filters__item_facade.filters__item_selected,
	.filters__item_facade:hover {
		background-color: #D45900;
	}
	.filters__item_bois-vernis {
		background-color: #897e65;
	}
	.filters__item_bois-vernis.filters__item_selected,
	.filters__item_bois-vernis:hover {
		background-color: #70654C;
	}
	.filters__item_sols {
		background-color: #70777a;
	}
	.filters__item_sols.filters__item_selected,
	.filters__item_sols:hover {
		background-color: #575E61;
	}
	.filters__item_metaux {
		background-color: #3c9ad5;
	}
	.filters__item_metaux.filters__item_selected,
	.filters__item_metaux:hover {
		background-color: #2381BC;
	}
	.filters__item_peintures-decoratives {
		background-color: #ddd70e;
	}
	.filters__item_peintures-decoratives.filters__item_selected,
	.filters__item_peintures-decoratives:hover {
		background-color: #C4BE00;
	}
}


.filters__label {
	padding-left: 0.5%;
	color: #333;
}