.map {
	position: relative;
	max-width: 1160px;
	margin: 0 auto;
    padding-bottom: 100%;
    height: 0;
    overflow: hidden;
}
	@media (min-width: 60em) {
		.map {
    		padding-bottom: 45%;
		}
	}


.map__canvas {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.map__title {
	margin: 0;
	font-weight: 300;
}