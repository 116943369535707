.title {
	padding: 1em 0.5em;
	background: url(../../images/activites/sprite.svg) no-repeat;
	background-size: auto 700%;
	color: #fff;
}
	@media (min-width: 31em) {
		.title {
			padding-right: 1em;
			padding-left: 1em;
		}
	}
	@media (min-width: 600px) {
		.title {
			background-size: 100% auto;
		}
	}
	@media (min-width: 662px) {
		.title {
			padding-right: 0;
			padding-left: 0;
		}
	}

.title_primaire-impression {
	background-position: 0 100%;
}
.title_finitions {
	background-position: 0 50.211778290993074%;
}
.title_facade {
	background-position: 0 16.772533179457586%;
}
.title_bois-vernis {
	background-position: 0 33.429861111111116%;
}
.title_sols {
	background-position: 0 66.91722543352603%;
}
.title_metaux {
	background-position: 0 0;
}
.title_peintures-decoratives {
	background-position: 0 83.48719723183392%;
}


.title__text {
	max-width: 630px;
	margin: 0 auto;
	font-weight: 300;
	font-size: 2.1em;
	text-transform: uppercase;
}