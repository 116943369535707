.sticky-footer {
	height: 100%;
}


.sticky-footer__content {
	min-height: 100%;
	/* equal to footer height */
	margin-bottom: -80px;
}
.sticky-footer__content_non-sticky {
	min-height: 0;
}


.sticky-footer__content:after {
  content: "";
  display: block;
}
.sticky-footer__footer, .sticky-footer__content:after {
  height: 80px;
}